<template>
  <div class="area-chart">
    <div>
      <div
        :class="[
          'prod-percentage',
          {'prod-percentage--orange': productivityChartData.percentage > 49},
          {'prod-percentage--green': productivityChartData.percentage > 99},
        ]"
      >
        {{ productivityChartData.percentage }}%
      </div>
    </div>
    <GChart
      :settings="{ packages: ['corechart', 'table', 'map'] }"
      :data="chartData"
      :options="chartOptions"
      :create-chart="(el, google) => new google.visualization.PieChart(el || $el)"
      class="Pie-chart"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { GChart } from 'vue-google-charts';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductivityScore',
  components: {
    GChart,
  },
  data: () => ({
    percentageGreen: 'percentage-green',

  }),

  computed: {
    ...mapGetters([
      'productivityChartData',
    ]),
    chartData() {
      // Worked Out
      const hoursWorkedOut = Math.floor(moment.duration(this.productivityChartData.workedOut, 'seconds').asHours());
      const minutesWorkedOut = moment.duration(this.productivityChartData.workedOut, 'seconds').minutes();

      // Left
      const hoursLeft = Math.floor(moment.duration(this.productivityChartData.leftWorked, 'seconds').asHours());
      const minutesLeft = moment.duration(this.productivityChartData.leftWorked, 'seconds').minutes();

      // Extra
      const hoursExtra = Math.floor(moment.duration(this.productivityChartData.workExtra, 'seconds').asHours());
      const minutesExtra = moment.duration(this.productivityChartData.workExtra, 'seconds').minutes();

      return [
        ['Effort', 'Amount given'],
        ['Worked',
          {
            v: this.productivityChartData.workedOut,
            f: `${hoursWorkedOut}h ${minutesWorkedOut}m`,
          },
        ],
        ['Left',
          {
            v: this.productivityChartData.leftWorked,
            f: `${hoursLeft}h ${minutesLeft}m`,
          },
        ],
        ['Extra',
          {
            v: this.productivityChartData.workExtra,
            f: `${hoursExtra}h ${minutesExtra}m`,
          },
        ],
      ];
    },
    chartOptions() {
      return {
        title: 'Productivity score',
        backgroundColor: 'transparent',
        titleTextStyle: {
          color: '#666F7A',
          fontSize: 14,
        },
        pieSliceText: 'none',
        pieSliceBorderColor: 'none',
        slices: {
          0: { color: '#60b6ff' },
          1: { color: '#f1f2f4' },
          2: { color: '#3F9EFF' },
        },
        pieHole: 0.6,
        pieSliceTextStyle: {
          color: '#666F7A',
        },
        tooltip: {
          // ignoreBounds: true,
          text: 'value',
          trigger: 'focus',
          textStyle: {
            color: '#666F7A',
            bold: true,
            fontSize: 12,
          },
        },
        legend: {
          position: 'none',
          alignment: 'start',
          textStyle: {
            color: '#666F7A',
            fontSize: 12,
          },
        },
      };
    },
  },
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
  .area-chart {
    margin-top : 20px;
    position   : relative;
  }

  .prod-percentage {
    color           : red;
    font-size       : 100%;
    position        : absolute;
    top             : 0;
    left            : 0;
    right           : 0;
    bottom          : 0;
    display         : grid;
    justify-content : center;
    align-items     : center;
    margin-left     : 60px;
  }

  .Pie-chart {
    margin-left : 60px;
  }

  .prod-percentage--orange {
    color : orange;
  }

  .prod-percentage--green {
    color : green;
  }
</style>
